import { Box } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Container = ({ children }: Props) => {
  return (
    <Box minWidth={{ base: '100vw', lg: 'fit-content' }}
    style={{
      "background":"url(/static/background-img.png)",
      // "background":"#FFF",
      "backgroundRepeat":"no-repeat",
      "backgroundPosition":"center",
      "backgroundSize":"cover"
    }}
    id='top-navbar-01'
    >
      { children }
    </Box>
  );
};

export default React.memo(Container);
