import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  GridProps,
  Image,
  Link,
  Text,
  VStack,
  Icon,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { route } from "nextjs-routes";
import IconSvg from "ui/shared/IconSvg";
const linkGroup = [
  {
    title: "Company",
    link: [
      { content: "About Us", url: "https://nowchain.co/" },
      { content: "Brand Assets", url: "#" },
      { content: "Contact Us", url: "https://nowchain.co/contact-us" },
      { content: "Careers", url: "https://nowchain.co/career" },
      {
        content: "Terms & Privacy",
        url: "https://nowchain.co/terms-conditions",
      },
      { content: "Bug Bounty", url: "#" },
    ],
  },
  {
    title: "Community",
    link: [
      { content: "API Documentation", url: "/docs-api" },
      { content: "Knowledge Base", url: "https://nowchain.co/" },
      { content: "News letters", url: "#" },
    ],
  },
  {
    title: "Products & Services",
    link: [
      { content: "Advertise", url: "#" },
      { content: "Explorer-as-a-Service(EaaS)", url: "#" },
      { content: "API Plans", url: "#" },
      { content: "Priotity Support", url: "#" },
      { content: "Blockscan", url: "#" },
      { content: "Blockscan Chat", url: "#" },
    ],
  },
];

const LIST_ICON = [
  {
    name: "footer-icon/facebook" as const,
    size: "40px",
    url: "https://www.facebook.com/NOWblockchain?mibextid=LQQJ4d",
  },
  {
    name: "footer-icon/telegram" as const,
    size: "40px",
    url: "https://t.me/NOWblockchain_community",
  },
  {
    name: "footer-icon/twitter" as const,
    size: "40px",
    url: "https://x.com/NOWChainX",
  },
  {
    name: "footer-icon/youtube" as const,
    size: "40px",
    url: "https://www.youtube.com/@NOWBlockchain",
  },
  // {
  //   name: 'footer-icon/github' as const,
  //   size: '40px',
  //   url: '#'
  // },
];

function scrollToTop() {
  const section = document.getElementById("top-navbar-01");
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

const containerProps: GridProps = {
  h: { base: "100%", lg: "280px" },
  templateColumns: { base: "repeat(2, 1fr)", lg: "repeat(5, 1fr)" },
  gap: { base: 2, lg: 6 },
  px: { base: 0, lg: 8 },
  pt: { base: 6, lg: 10 },
  pb: { base: 1, lg: 5 },
};

const Footer = () => {
  return (
    <Box as="footer" pt={{ base: 4, lg: 6 }}>
      {/* Icon */}
      <Flex
        justify={"space-between"}
        pt="3"
        pb={{ base: 3, lg: 10 }}
        px={{ base: 8, lg: 24 }}
      >
        <HStack spacing={{ base: 3, lg: 5 }}>
          {LIST_ICON.map((icon, _index) => (
            <Link
              key={_index}
              href={icon.url}
              target="_blank"
              display="flex"
              alignItems="center"
              h="40px"
            >
              <Center minW={6}>
                <IconSvg boxSize={{ base: 6, lg: 10 }} name={icon.name} />
              </Center>
            </Link>
          ))}
        </HStack>

        <Box
          as="button"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={scrollToTop}
          color={"#6c757d"}
        >
          <IconSvg
            boxSize={5}
            color={"gray.200"}
            name={"footer-icon/up-to-top" as const}
          />
          <Text pl="1" color={"gray.200"} fontSize={{ base: "xs", lg: "md" }}>
            Back To Top
          </Text>
        </Box>
      </Flex>
      <Box px={{ base: 8, lg: 24 }} borderTop={"1px solid #ffffff14"}>
        <Container maxW={"1680px"} px={0}>
          <Grid {...containerProps}>
            <GridItem colSpan={2} h="100%" mb={{ base: 5, lg: 0 }}>
              <Flex
                h={"100%"}
                w={"100%"}
                flexDirection={"column"}
                justify={"center"}
                alignItems={"start"}
                rowGap={{ base: 3, lg: 5 }}
                mb={{ base: 5, lg: 0 }}
              >
                <Box
                  as="a"
                  href={route({ pathname: "/" })}
                  width={{ base: "154px", lg: "189px" }}
                  height={{ base: "100px", lg: "120px" }}
                  display={{ base: "inline-flex" }}
                  // justifyContent={'center'}
                  overflow="hidden"
                  flexGrow={0}
                  aria-label="Link to main page"
                >
                  <Image
                    src={"/static/logo-now-footer.png"}
                    alt="icon-logo-tobescan"
                    w="auto"
                    h="100%"
                  />
                </Box>

                <Text fontSize="sm" color={"gray.400"}>
                  Nowscan is a Block Explorer and Analytics Platform for NOW
                  chain, <br />a decentralized smart contracts platform.
                </Text>
              </Flex>
            </GridItem>
            {linkGroup.map((itemlink, index) => (
              <GridItem
                key={index}
                colSpan={{ base: index === 2 ? 2 : 1, lg: 1 }}
                h="100%"
                pl={{ base: 0, lg: 3 }}
                mb={{ base: 5, lg: 0 }}
              >
                <VStack spacing={1} alignItems="start">
                  <Text fontWeight="600" mb="1" fontSize="md">
                    {itemlink.title}
                  </Text>
                  <Box as={"ul"} listStyleType={"none"}>
                    {itemlink.link.map((subLink, _subIndex) => (
                      <Box as="li" key={_subIndex}>
                        <Link
                          color={"gray.400"}
                          href={subLink.url}
                          display="flex"
                          alignItems="center"
                          h="26px"
                          variant="secondary"
                          // target="_blank"
                          fontSize="xs"
                          flexWrap={{ base: "nowrap", lg: "wrap" }}
                        >
                          {subLink.content}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Container>
        <Box borderTop={"1px solid #ffffff14"}>
          <Text
            fontSize={{ base: "xs", lg: "sm" }}
            fontWeight={500}
            py={{ base: 2, lg: 3 }}
            color={"gray.400"}
          >
            Nowscan © 2025
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
